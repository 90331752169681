import React, {
  FunctionComponent,
  useLayoutEffect,
  useRef,
  useState,
  useCallback
} from 'react'

import { closePopover } from '../redux/ui'
import { useDispatch } from 'react-redux'
import styled from '@mui/styled-engine'

type StyledProps = {
  top: string
  left: string
}

const Content = styled('div')(({ left, top }: StyledProps) => ({
  position: 'absolute',
  left: left,
  top: top,
  background: 'white',
  padding: '8px',
  borderRadius: '4px',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.2)',
  zIndex: '40'
}))

type Props = {
  className?: string
  parentRef?: HTMLElement
}
// @ts-ignore
const Menu: FunctionComponent<Props> = ({ className, children, parentRef }) => {
  const [position, setPosition] = useState<StyledProps>({
    top: '200vh',
    left: '-100vw'
  })
  const ref = useRef<any>(null)
  const dispatch = useDispatch()

  const isOpen = !!parentRef && !!children

  const checkClickedOutside = useCallback(
    (event: any) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        dispatch(closePopover())
      }
    },
    [isOpen, ref, dispatch]
  )

  useLayoutEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', checkClickedOutside)
    }

    if (isOpen && ref.current && parentRef && parentRef) {
      const { height: menuHeight, width: menuWidth } =
        ref.current.getBoundingClientRect()
      const { top: parentTop, left: parentLeft } =
        parentRef.getBoundingClientRect()
      const WINDOW_MARGIN = 10
      const TRESHOLD = 5

      const adjustedLeft =
        parentLeft + menuWidth + WINDOW_MARGIN > window.innerWidth
          ? window.innerWidth - menuWidth - WINDOW_MARGIN
          : parentLeft

      setPosition({
        top: `${window.scrollY + parentTop - menuHeight - TRESHOLD}px`,
        left: `${adjustedLeft}px`
      })
    }

    return () => {
      document.removeEventListener('mousedown', checkClickedOutside)
    }
  }, [parentRef, checkClickedOutside, isOpen])

  const { top, left } = position

  if (!isOpen) {
    return null
  }

  return (
    <Content className={className} ref={ref} top={top} left={left}>
      {children}
    </Content>
  )
}

export default Menu
